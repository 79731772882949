<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :width="800"
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="设置限额"
      @ok="handleSubmit"
    >
      <a-row>
        <a-form
          class="iotplt-compact-form"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 15 }"
          :form="form"
          @submit="handleSubmit"
        >
          <a-form-item
            :wrapper-col="{ span: 24 }"
          >
            <common-card-input
              :rows="15"
              v-decorator="['iccids', {
                initialValue: { data: this.iccids },
                rules: [{ validator: checkIccids }],
              }]"
            />
          </a-form-item>

          <a-form-item
            label="限额方式"
          >
            <a-radio-group
              v-decorator="['is_limit',{
                rules: [
                  { required: true, message: '请选择限额方式' },
                ]
              }]"
            >
              <a-row style="margin-top: 10px">
                <a-col :span="8">
                  <a-radio :value="false">
                    取消限额
                  </a-radio>
                  <a-radio
                    :value="true"
                    class="vertical-radio"
                  >
                    设置限额
                  </a-radio>
                </a-col>
              </a-row>
            </a-radio-group>

            <a-form-item
              class="limit-type-setting-box"
              v-if="isShowLimitTypeSetting"
            >
              <a-radio-group
                v-decorator="['limit_type',{
                  rules: [
                    { required: true, message: '请设置限额' },
                  ]
                }]"
              >
                <a-row>
                  <a-radio
                    class="vertical-radio"
                    value="percentage"
                  >
                    百分比
                  </a-radio>

                  <a-form-item
                    :style="{ display: 'inline-block', marginBottom: 0 }"
                  >
                    <a-input-number
                      v-if="isShowPercentageSetting"
                      :min="1"
                      :max="1000000"
                      :step="1"
                      :precision="0"
                      style="width: 150px;"
                      v-decorator="['limit_value', {
                        rules: [
                          { required: true, message: '请输入限额值' },
                        ]
                      }]"
                    />
                    <span
                      class="limit-unit"
                      v-if="isShowPercentageSetting"
                    >%</span>
                  </a-form-item>
                </a-row>

                <a-row>
                  <a-radio
                    style="margin-top: 8px;"
                    value="absolute_value"
                  >
                    绝对值
                  </a-radio>

                  <a-form-item
                    :style="{ display: 'inline-block', marginBottom: 0 }"
                  >
                    <a-input-number
                      v-if="isShowAbsoluteValueSetting"
                      :min="1"
                      :max="1000000"
                      :step="1"
                      :precision="0"
                      style="width: 150px;"
                      v-decorator="['limit_value', {
                        rules: [
                          { required: true, message: '请输入限额值' },
                        ]
                      }]"
                    />
                  </a-form-item>

                  <a-form-item class="limit-unit">
                    <a-select
                      v-if="isShowAbsoluteValueSetting"
                      v-decorator="['limit_value_unit',{
                        initialValue: 'mb',
                        rules: [
                          { required: true, message: '请选择流量单位' }
                        ]
                      }]"
                    >
                      <a-select-option
                        v-for="option in limitValueUnitOptions"
                        :key="option.value"
                        :value="option.value"
                      >
                        {{ option.label }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-row>
              </a-radio-group>
            </a-form-item>
          </a-form-item>

          <a-form-item label="备注">
            <a-textarea
              v-decorator="['remark', {
                rules: [
                  { max: 200, message: '最多200个字符' },
                ]
              }]"
              :auto-size="{ minRows: 3, maxRows: 5 }"
            />
          </a-form-item>

          <a-form-item label="提醒">
            超额流量（超出套餐额定流量）费用，需要您承担和支付。
          </a-form-item>
        </a-form>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import { setSimCardsUsageLimit } from '@/api/batch_operation'
import CommonCardInput from '@/components/CardInput/Common'

export default {
  name: 'BatchOperationSetSimCardsUsageLimit',
  components: { CommonCardInput },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    iccids: {
      type: Array,
      default() {
        return []
      }
    },
    agentId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'set_sim_cards_usage_limit', onValuesChange: this.onValuesChange }),
      limitValueUnitOptions: [
        { label: 'MB', value: 'mb' },
        { label: 'GB', value: 'gb' },
        { label: '次', value: 'count' }
      ],
      isShowLimitTypeSetting: false, // 是否显示设置限额类型选项
      isShowPercentageSetting: false, //  是否显示百分比设置
      isShowAbsoluteValueSetting: false, // 是否显示绝对值设置
      submitting: false
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    onValuesChange(props, values) {
      if (values.is_limit !== undefined) {
        this.clearLimitValueSetting()
        this.isShowLimitTypeSetting = values.is_limit
      }

      if (values.limit_type !== undefined) {
        this.clearLimitValueSetting()
        switch (values.limit_type) {
          case 'percentage':
            this.isShowPercentageSetting = true
            break
          case 'absolute_value':
            this.isShowAbsoluteValueSetting = true
            break
        }
      }
    },

    clearLimitValueSetting() {
      this.isShowPercentageSetting = false
      this.isShowAbsoluteValueSetting = false
      this.form.setFieldsValue({
        limit_value: undefined
      })
    },

    checkIccids(rule, value, callback) {
      if (!value || !value.inputValue) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('请输入卡号')
        return
      }

      if (value.validateStatus === 'error') {
        callback(value.errorMsg)
        return
      }

      // eslint-disable-next-line standard/no-callback-literal
      callback()
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const data = {
            ...values,
            iccids: values.iccids.data,
            agent_id: this.agentId
          }
          console.log(data)
          setSimCardsUsageLimit(data).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
              this.$success({
                title: '提交成功',
                content: (
                  <div>
                    <p>操作成功，具体操作结果请至 【系统查询】-【批量操作查询】 查看</p>
                  </div>
                )
              })
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.vertical-radio {
  margin-top: 8px;
}

.limit-type-setting-box {
  margin-left: 30px;
  margin-top: -10px;
}

.limit-unit{
  display: inline-block;
  margin-bottom: 0;
  margin-left: 10px;
  width: 60px;
}
</style>
