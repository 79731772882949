var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"centered":"","width":800,"mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"设置限额"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-row',[_c('a-form',{staticClass:"iotplt-compact-form",attrs:{"label-col":{ span: 4 },"wrapper-col":{ span: 15 },"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"wrapper-col":{ span: 24 }}},[_c('common-card-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['iccids', {
              initialValue: { data: this.iccids },
              rules: [{ validator: _vm.checkIccids }],
            }]),expression:"['iccids', {\n              initialValue: { data: this.iccids },\n              rules: [{ validator: checkIccids }],\n            }]"}],attrs:{"rows":15}})],1),_c('a-form-item',{attrs:{"label":"限额方式"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['is_limit',{
              rules: [
                { required: true, message: '请选择限额方式' } ]
            }]),expression:"['is_limit',{\n              rules: [\n                { required: true, message: '请选择限额方式' },\n              ]\n            }]"}]},[_c('a-row',{staticStyle:{"margin-top":"10px"}},[_c('a-col',{attrs:{"span":8}},[_c('a-radio',{attrs:{"value":false}},[_vm._v(" 取消限额 ")]),_c('a-radio',{staticClass:"vertical-radio",attrs:{"value":true}},[_vm._v(" 设置限额 ")])],1)],1)],1),(_vm.isShowLimitTypeSetting)?_c('a-form-item',{staticClass:"limit-type-setting-box"},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['limit_type',{
                rules: [
                  { required: true, message: '请设置限额' } ]
              }]),expression:"['limit_type',{\n                rules: [\n                  { required: true, message: '请设置限额' },\n                ]\n              }]"}]},[_c('a-row',[_c('a-radio',{staticClass:"vertical-radio",attrs:{"value":"percentage"}},[_vm._v(" 百分比 ")]),_c('a-form-item',{style:({ display: 'inline-block', marginBottom: 0 })},[(_vm.isShowPercentageSetting)?_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['limit_value', {
                      rules: [
                        { required: true, message: '请输入限额值' } ]
                    }]),expression:"['limit_value', {\n                      rules: [\n                        { required: true, message: '请输入限额值' },\n                      ]\n                    }]"}],staticStyle:{"width":"150px"},attrs:{"min":1,"max":1000000,"step":1,"precision":0}}):_vm._e(),(_vm.isShowPercentageSetting)?_c('span',{staticClass:"limit-unit"},[_vm._v("%")]):_vm._e()],1)],1),_c('a-row',[_c('a-radio',{staticStyle:{"margin-top":"8px"},attrs:{"value":"absolute_value"}},[_vm._v(" 绝对值 ")]),_c('a-form-item',{style:({ display: 'inline-block', marginBottom: 0 })},[(_vm.isShowAbsoluteValueSetting)?_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['limit_value', {
                      rules: [
                        { required: true, message: '请输入限额值' } ]
                    }]),expression:"['limit_value', {\n                      rules: [\n                        { required: true, message: '请输入限额值' },\n                      ]\n                    }]"}],staticStyle:{"width":"150px"},attrs:{"min":1,"max":1000000,"step":1,"precision":0}}):_vm._e()],1),_c('a-form-item',{staticClass:"limit-unit"},[(_vm.isShowAbsoluteValueSetting)?_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['limit_value_unit',{
                      initialValue: 'mb',
                      rules: [
                        { required: true, message: '请选择流量单位' }
                      ]
                    }]),expression:"['limit_value_unit',{\n                      initialValue: 'mb',\n                      rules: [\n                        { required: true, message: '请选择流量单位' }\n                      ]\n                    }]"}]},_vm._l((_vm.limitValueUnitOptions),function(option){return _c('a-select-option',{key:option.value,attrs:{"value":option.value}},[_vm._v(" "+_vm._s(option.label)+" ")])}),1):_vm._e()],1)],1)],1)],1):_vm._e()],1),_c('a-form-item',{attrs:{"label":"备注"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['remark', {
              rules: [
                { max: 200, message: '最多200个字符' } ]
            }]),expression:"['remark', {\n              rules: [\n                { max: 200, message: '最多200个字符' },\n              ]\n            }]"}],attrs:{"auto-size":{ minRows: 3, maxRows: 5 }}})],1),_c('a-form-item',{attrs:{"label":"提醒"}},[_vm._v(" 超额流量（超出套餐额定流量）费用，需要您承担和支付。 ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }